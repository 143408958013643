import payload_plugin_qLmFnukI99 from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_prod_client_jEf4DH9FfC from "/app/node_modules/nuxt-monaco-editor/dist/runtime/plugin-prod.client.js";
import plugin_client_5j40ijIZYu from "/app/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import floating_vue_Bn91Y1go2z from "/app/src/plugins/floating-vue.js";
import sentry_sCeXubc6q1 from "/app/src/plugins/sentry.js";
import plugin_auto_pageviews_client_x3Y8XIaaPY from "/app/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_prod_client_jEf4DH9FfC,
  plugin_client_5j40ijIZYu,
  floating_vue_Bn91Y1go2z,
  sentry_sCeXubc6q1,
  plugin_auto_pageviews_client_x3Y8XIaaPY
]